import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import RichText from "components/elements/richText"
import "./centerFeatures.scss"
import Button from "components/elements/styledButton"
const CenterFeatures = ({
  action,
  backgroundColor,
  description,
  featureTitle,
  features,
}) => {
  return (
    <div
      className={renderClasses("center-features", [
        [backgroundColor, `--${backgroundColor}`],
      ])}
    >
      <div className="center-features__wrapper">
        {featureTitle && (
          <h2 className="center-features__title">{featureTitle}</h2>
        )}

        {/* Features Grid */}
        <div className="center-features__grid">
          {features.map((feature, index) => (
            <div className="center-features__card" key={index}>
              {feature.stickerMedia?.image?.file?.url && (
                <img
                  alt={feature.stickerMedia.image.alt || ""}
                  className="center-features__icon"
                  src={feature.stickerMedia.image.file.url}
                />
              )}
              <p className="center-features__text">{feature.stickerText}</p>
            </div>
          ))}
        </div>

        {/* Description */}
        {description && (
          <RichText
            className="center-features__description"
            richText={description.details}
            textStyle="full"
          />
        )}

        {/* Call to Action Button */}
        {action && <Button {...action} className="text-lockup__button" />}
      </div>
    </div>
  )
}

CenterFeatures.propTypes = {
  action: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    isOutlined: PropTypes.bool,
  }),
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
  ]),
  description: PropTypes.shape({
    details: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }).isRequired,
  }),
  featureTitle: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      stickerText: PropTypes.string,
      stickerMedia: PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
          alt: PropTypes.string,
        }),
      }),
    })
  ),
}

export default CenterFeatures
