import React from "react"
import PropTypes from "prop-types"
import SplitSection from "components/sections/splitSection"
import SECTIONS from "utils/sectionConstants"
import Gallery from "views/gallery"
import Wrapper from "components/sections/wrapper"
import InsuranceBanner from "components/sections/insuranceBanner"
import CenterFeatures from "components/sections/centerFeatures"
import COMPONENTS from "utils/componentConstants"
import LocationCard from "components/blocks/locationCard"
import Map from "../../components/elements/map/index"
import { googleMapURL } from "../../utils/constants"
import "./template.scss"
const PlanoLocationTemplate = ({ gallery, sections }) => (
  <>
    {sections.map((section, i) => {
      switch (section.__typename) {
        case SECTIONS.WrapperSection:
          return <Wrapper {...section} className="headingText" />
        case SECTIONS.TemplateDetailsSection:
          return <Gallery gallery={gallery} key={i} />
        case COMPONENTS.InsuranceBanner:
          return (
            <InsuranceBanner
              className="payment-options-banner players"
              displayMode="logos"
              key={i}
              {...section}
            />
          )
        case SECTIONS.CenterFeatures:
          return <CenterFeatures {...section} key={i} />
        case SECTIONS.MapSection:
          return (
            <div className="map-info">
              <LocationCard
                {...section}
                sidebarDetails={section.sidebarDetails}
              />
              <div className="clinic-map">
                <Map
                  containerElement={
                    <div style={{ height: `368px`, width: `100%` }} />
                  }
                  googleMapURL={googleMapURL}
                  isMarkerShown={true}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={
                    <div style={{ height: `100%`, borderRadius: `6px` }} />
                  }
                  markerPosition={[
                    {
                      info: section.detailedAddress,
                      lat: section.address.lat,
                      lon: section.address.lon,
                    },
                  ]}
                  zoomChange={() => {}}
                />
              </div>
            </div>
          )
        default:
          return null
      }
    })}
  </>
)

PlanoLocationTemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(SplitSection.props)])
  ),
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

PlanoLocationTemplate.propTypes = PlanoLocationTemplate.props

export default PlanoLocationTemplate
