import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import PlanoLocationTemplate from "./template"
import LOGO from "images/cortica-logo.svg"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { footer, header, template } }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    logo: LOGO,
    name: "Cortica Care",
    sameAs: [
      "https://twitter.com/CorticaCare",
      "https://www.facebook.com/CorticaCare",
      "https://www.linkedin.com/company/cortica-/",
      "https://www.instagram.com/corticacare/",
    ],
    url: siteUrl,
  }

  return (
    <>
      <Seo {...template.meta} schemaMarkup={schema} slug={template.slug} />
      <Header {...header} />
      <PlanoLocationTemplate {...template} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...PlanoLocationTemplate.props,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(TemplateContainer.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query locationPage($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header-WCCC" }) {
      backgroundColor
      isWholeChild
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer-WCCC" }) {
      backgroundColor
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    template: contentfulPlanoPageTemplate(slug: { eq: $slug }) {
      slug
      title
      meta: seoMetadata {
        canonical
        title
      }
      gallery {
        title
        file {
          url
        }
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sections {
        __typename
        ... on ContentfulCenterFeatures {
          featureTitle
          description {
            ... on ContentfulSectionRichText {
              __typename
              alignment
              details {
                json
              }
            }
          }
          backgroundColor
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          features {
            stickerText
            stickerMedia {
              image {
                alt: description
                title
                file {
                  url
                }
              }
            }
          }
        }
        ... on ContentfulSectionMap {
          __typename
          detailedAddress
          fax
          phone
          postalCode
          address {
            lat
            lon
          }
          sidebarDetails {
            json
          }
        }
        ... on ContentfulSectionInsuranceBanner {
          bannerText
          providers
          providersLogos {
            title
            file {
              url
            }
          }
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
        }
        ... on ContentfulSectionWrapper {
          __typename
          backgroundStyle
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          wrapperSections: sections {
            __typename

            ... on ContentfulSectionRichText {
              __typename
              alignment
              details {
                json
              }
            }
            ... on ContentfulSectionText {
              __typename
              headline
              style
              title
              body {
                body
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulVideoWrapper {
              __typename
              title
              url
            }
          }
        }
        ... on ContentfulTemplateSection {
          __typename
          title
          additionalSettings {
            enableDirectionsCTA
          }
        }
      }
    }
  }
`
